import { FC, PropsWithChildren } from 'react';
import { useEmbedCheck } from '../../../utils/hooks';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
    syncs?: boolean;
    hideCredits?: boolean;
};

const EmbeddableWrapper: FC<Props> = ({ children, syncs, hideCredits }) => {
    const isEmbedded = useEmbedCheck();

    return (
        <div 
            className={classNames(
                !isEmbedded && 'w-screen h-screen flex flex-col items-center justify-center p-12 dark:bg-stone-900'
            )}
        >
            { children }
            <div className="flex justify-between items-center w-full mt-2">
                <p className="text-black dark:text-white opacity-30 text-sm">{ syncs && <>Synced with this domain or instance only</>}</p>
                <p className="self-end text-xs text-black dark:text-white uppercase tracking-wider font-medium">
                    { !hideCredits && <><span className="opacity-40">Edit on</span> <a href={ window.location.href } className="opacity-50 underline underline-offset-4">notion-blocks</a></> }
                </p>
            </div>
        </div>
    )
}

export default EmbeddableWrapper;