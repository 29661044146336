import classNames from 'classnames';
import { FC } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import library from '../library';

interface Props {};

const GenerateSite: FC<Props> = () => {
    return (
        <div className="grid grid-cols-12 w-screen h-screen">
            <aside className="col-span-4 border-r border-stone-200 dark:border-stone-500">
                <div className="p-2">
                    { library.map((block, index) => (
                        <NavLink 
                            key={ index }
                            to={ block.slug } 
                            className={({ isActive }) => classNames(
                                'block w-full dark:text-white hover:bg-stone-200 dark:hover:bg-stone-700 px-3 py-2 rounded-[4px] mb-2',
                                isActive && 'bg-stone-100 dark:bg-stone-800'
                            )}
                        >{ block.label }</NavLink>
                    ))}
                </div>
            </aside>
            <main className="col-span-8">
                <Outlet />
            </main>
        </div>
    )
}

export default GenerateSite;