import { FC, HTMLInputTypeAttribute, useState } from 'react';
import EmbeddableWrapper from '../EmbeddableWrapper/EmbeddableWrapper';
import classNames from 'classnames';

interface Props {
    value?: any;
    type?: HTMLInputTypeAttribute;
    label?: string;
    width?: 'stretch' | 'auto';
};

const InputEmbeddable: FC<Props> = ({ value, type, label, width }) => {
    const [ showPassword, setShowPassword ] = useState(false);
    
    const isPassword = type === 'password';
    
    return (
        <EmbeddableWrapper hideCredits>
            <div className={classNames(width !== 'auto' && 'w-full')}>
                { !!label && <div className="uppercase tracking-wider font-medium text-sm text-black dark:text-stone-400 mb-1">{ label }</div>}
                <div className="flex gap-4">
                    <input
                        value={ value } 
                        type={ isPassword ? showPassword ? 'text' : 'password' : type } 
                        placeholder="Configure a value" 
                        disabled
                        className="border border-stone-600 bg-transparent px-3 py-2 text-white rounded-[4px] select-none outline-none placeholder:text-stone-500 w-full"
                    />
                    { isPassword && (
                        <button 
                            onClick={() => setShowPassword(s => !s)}
                            className="dark:text-stone-400 uppercase tracking-wider font-medium text-sm"
                        >
                            { showPassword ? 'Hide' : 'View' }
                        </button>
                    )}
                </div>
            </div>
        </EmbeddableWrapper>
    )
}

export default InputEmbeddable;