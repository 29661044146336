import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import Embed from '../../../../components/basics/embeddables';

interface Props {};

const InputEmbed: FC<Props> = () => {
    const [ searchParams ] = useSearchParams();
    
    // encodeURI(JSON.stringify({ label: 'Email', type: 'text', value: 'test@test.be', width: 'stretch" }));
    
    const configParam = searchParams.get('config');
    const configuration = JSON.parse(configParam || '{}');
    
    return (
        <Embed.Input value={ configuration.value } label={ configuration.label } type={ configuration.type } width={ configuration.width } />
    )
}

export default InputEmbed;