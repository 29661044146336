import classNames from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Embed from '../../../../components/basics/embeddables';
import useBlocksStore from '../../../../state/stores/useBlocksStore/useBlocksStore';
import { CounterBlock, StoredBlock } from '../../../../types/blocks';
import { useEmbedCheck } from '../../../../utils/hooks';
import EmbeddableWrapper from '../../../../components/basics/EmbeddableWrapper/EmbeddableWrapper';

interface Props {};

const CounterEmbed: FC<Props> = () => {
    const isEmbedded = useEmbedCheck();
    const [ searchParams ] = useSearchParams();
    const params = useParams<any>();
    const saveBlockState = useBlocksStore((state) => (freshState: CounterBlock) => state.saveState<CounterBlock>(params.id as string, freshState));
    const block = useBlocksStore((state) => state.getState<CounterBlock>(params.id as string));
    
    const blockTitle = searchParams.get('title') || 'Block title';
    const goal = !!searchParams.get('goal') ? parseInt(searchParams.get('goal') as string) : block?.state.goal || 0;
    const count = block?.state.count || 0;
    const increaseBy = block?.state.increaseBy || 1;
    
    const setCount = useCallback((amount: number) => {
        if (block?.state) {
            saveBlockState({
                ...block?.state,
                count: amount
            })
        } else {
            saveBlockState({
                title: blockTitle,
                goal,
                increaseBy,
                count: amount
            })
        }
    }, [block?.state, blockTitle, goal, increaseBy ])
    
    const increase = useCallback(() => {
        setCount(count + increaseBy);
    }, [ count, increaseBy ])
    
    const decrease = useCallback(() => {
        setCount(count - increaseBy);
    }, [ count, increaseBy ])

    return (
        <EmbeddableWrapper>
            <Embed.Counter 
                count={ count }
                title={ blockTitle || block?.state.title } 
                goal={ goal }
                increaseBy={ 1 }
                increase={ increase }
                decrease={ decrease }
            />
        </EmbeddableWrapper>
    )
}

export default CounterEmbed;