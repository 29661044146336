import ButtonEmbeddable from "./ButtonEmbeddable";
import CounterEmbeddable from "./CounterEmbeddable";
import InputEmbeddable from "./InputEmbeddable";

const Embed = {
    Button: ButtonEmbeddable,
    Counter: CounterEmbeddable,
    Input: InputEmbeddable,
}

export default Embed;