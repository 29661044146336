import { FC, useCallback, useState } from 'react';
import { CounterBlock } from '../../../types/blocks';

interface Props extends Partial<CounterBlock> {
    increase?: () => void;
    decrease?: () => void;
};

const CounterEmbeddable: FC<Props> = ({ count, goal, title, increase, decrease }) => {   
    return (
        <div className="border border-stone-300 dark:border-stone-500 p-4 rounded-xl w-full flex justify-between">
            <div>
                <h3 className="text-lg uppercase tracking-widest text-stone-600 dark:text-stone-400 font-semibold">{ title || 'Set block title' }</h3>
                <div className="font-body text-3xl text-black dark:text-white">{ count }{ !!goal && <> / { goal }</>}</div>
            </div>
            <div className="flex flex-col items-center justify-center gap-3">
                <button className="bg-stone-200 dark:bg-stone-500 w-7 h-7 rounded-full text-lg dark:text-white disabled:opacity-50" onClick={ increase } disabled={ !increase }>+</button>
                <button className="bg-stone-200 dark:bg-stone-500 w-7 h-7 rounded-full text-lg dark:text-white disabled:opacity-50" onClick={ decrease } disabled={ !decrease || count === 0 }>-</button>
            </div>
        </div>
    )
}

export default CounterEmbeddable;