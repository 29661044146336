import classNames from "classnames";
import {
  RouterProvider,
} from "react-router-dom";
import appRouter from "./App.router";
import './scss/index.scss';
import { useEmbedCheck } from "./utils/hooks";

const App = () => {
  const isEmbedded = useEmbedCheck();
  
  return (
    <div className={classNames(
      'App',
      'w-screen h-screen',
      !isEmbedded && 'dark:bg-stone-900'
    )}>
      <RouterProvider router={ appRouter } />
    </div>
  );
}

export default App;
