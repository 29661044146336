import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { ButtonBlock } from '../../../types/blocks';

interface Props extends Partial<ButtonBlock> {
    children?: string | null;
};

const ButtonEmbeddable: FC<Props> = ({ children, href, stretch }) => {
    const hasContent = useMemo(() => !children, [ children ]);
        
    return (
        <a 
            href={ href || '' }
            target="_blank"
            className={classNames(
                'block border border-stone-600 bg-stone-800 px-3 py-2 text-white rounded-[4px] text-center select-none',
                !hasContent && 'text-opacity-50 pointer-events-none',
                stretch ? 'w-full' : 'w-fit'
            )}
        >
            { children }
        </a>
    )
}

export default ButtonEmbeddable;