import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import GenerateSite from "./sites/Generate/GenerateSite";
import library from "./sites/library";

const appRouter = createBrowserRouter(
    [
        {
            path: '/',
            index: true,
            element: <Navigate to="generate" />,
        },
        {
            path: 'generate',
            element: <GenerateSite />,
            children: [
                ...library.map<RouteObject>((block) => ({
                    path: block.slug,
                    children: [
                        { index: true, element: <block.baseBlock />},
                        ...( block.pathMatches.length === 0 ? [] : block.pathMatches.map<RouteObject>((match) => ({
                            path: match,
                            element: <block.baseBlock />
                        })))
                    ]
                })),
                // { path: 'button', element: <ButtonEmbedPage /> },
                // { path: 'counter', children: [
                //     { index: true, element: <CounterGenerator /> },
                //     { path: ':id', element: <CounterGenerator /> },
                // ]},
                // { path: 'input', element: <InputEmbeddable /> }
            ]
        },
        {
            path: 'embed',
            children: [
                ...library.map<RouteObject>((block) => ({
                    path: block.slug,
                    children: [
                        { index: true, element: <block.embed />},
                        ...( block.pathMatches.length === 0 ? [] : block.pathMatches.map<RouteObject>((match) => ({
                            path: match,
                            element: <block.embed />
                        })))
                    ]
                })),
            ]
        }
    ]
);

export default appRouter;