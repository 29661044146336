import create from 'zustand';
import { persist } from 'zustand/middleware'
import { CounterBlock, ID, StoredBlock } from '../../../types/blocks';
import produce from 'immer'


interface UseBlockStore {
    blocks: StoredBlock[];
    
    getState: <BlockState = any>(id: ID) => StoredBlock<BlockState> | undefined;
    saveState: <BlockState = any>(id: ID, newState: BlockState) => void;
    
    [key: string]: any;
}

const test = <T>() => {
    
}

const useBlocksStore = create(
    persist<UseBlockStore>((set, get) => ({
        blocks: [],
        
        getState: <BlockState = any>(id: ID) => {
            return get().blocks.find((block) => block.id === id) as BlockState;
        },
        
        saveState: <BlockState = any>(id: ID, newState: BlockState) => {
            const blockExists = get().getState(id);
            const blocks = get().blocks;    
                    
            if (blockExists) {
                const updatedBlocks = blocks.map((block) => {
                    if (block.id === id) {
                        return {
                            ...block,
                            state: newState
                        }
                    }
                    
                    return block;
                })
                
                set(
                    produce<UseBlockStore>((state) => {
                        state.blocks = updatedBlocks
                    })
                )
            } else {
                set(
                    produce<UseBlockStore>((state) => {
                        state.blocks = [...blocks, {
                            id,
                            type: 'counter',
                            state: newState
                        }];
                    })
                )
            }
        }
    }), {
        name: 'block-storage',
        getStorage: () => localStorage,
    })
);

export default useBlocksStore;