import { FunctionComponent } from "react";
import InputEmbeddable from "../components/basics/embeddables/InputEmbeddable";
import InputEmbed from "./Embed/pages/InputEmbed/InputEmbed";
import CounterEmbeddable from "../components/basics/embeddables/CounterEmbeddable";
import CounterEmbed from "./Embed/pages/CounterEmbed/CounterEmbed";

interface Block {
    label: string;
    baseBlock: FunctionComponent;
    embed: FunctionComponent;
    slug: string;
    pathMatches: string[],
    storage: boolean;
}

const library: Block[] = [
    {
        label: 'Input',
        baseBlock: InputEmbeddable,
        embed: InputEmbed,
        slug: 'input',
        pathMatches: [],
        storage: false
    },
    {
        label: 'Counter',
        baseBlock: CounterEmbeddable,
        embed: CounterEmbed,
        slug: 'counter',
        pathMatches: [':id'],
        storage: true
    },
    
]

export default library;